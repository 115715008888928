// @flow
import React from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native-web";
import { ReactComponent as IconSwitch } from "../../static/icons/switch.svg";
import { ReactComponent as IconCallEnd } from "../../static/icons/callEnd.svg";
import { ReactComponent as IconScreen } from "../../static/icons/screen.svg";
import { isMobile, isDesktop } from "../App";
import { IS_CORDOVA } from "../../constants";
import { leaveRoom } from "../../utils/socket";
import {
  switchCamera,
  switchScreen,
  backToCamera
} from "../../utils/localStream";
import { autobind } from "core-decorators";

class Navigation extends React.Component {
  state = {
    screenSharing: false
  };

  @autobind
  toggleScreenSharing() {
    this.setState({
      screenSharing: !this.state.screenSharing
    });
  }

  @autobind
  async switchScreen() {
    await switchScreen();
    this.toggleScreenSharing();
  }

  @autobind
  switchCamera() {
    backToCamera();
    this.toggleScreenSharing();
  }

  render() {
    const { localhost } = this.props;
    const { screenSharing } = this.state;

    const desktopStyles = [
      styles.buttonRounded,
      styles.buttonRoundedSmall,
      styles.buttonRoundedMedium,
      styles.buttonRoundedLarge
    ];

    return (
      <View style={[styles.navigation, isDesktop && styles.navigationMedium]}>
        {(IS_CORDOVA || isMobile) && (
          <TouchableOpacity
            style={[
              styles.buttonRounded,
              styles.buttonRoundedSmall,
              isDesktop && styles.buttonRoundedMedium,
              isDesktop && styles.buttonRoundedLarge
            ]}
            onPress={switchCamera}
          >
            <IconSwitch />
          </TouchableOpacity>
        )}
        {!IS_CORDOVA &&
          isDesktop &&
          (screenSharing ? (
            <TouchableOpacity style={desktopStyles} onPress={this.switchCamera}>
              <IconSwitch />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity style={desktopStyles} onPress={this.switchScreen}>
              <IconScreen />
            </TouchableOpacity>
          ))}
        <TouchableOpacity
          style={[
            styles.buttonRounded,
            isMobile && styles.buttonRoundedSmall,
            isDesktop && styles.buttonRoundedMedium
          ]}
          onPress={() => leaveRoom(localhost)}
        >
          <IconCallEnd />
        </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  navigation: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 800,
    height: 80,
    position: "absolute",
    bottom: 10,
    right: 0,
    left: 0,
    margin: "auto",
    zIndex: 2
  },
  navigationMedium: {
    bottom: 30,
    right: 30,
    left: 30
  },
  buttonRounded: {
    width: 60,
    height: 60,
    borderRadius: "50%",
    backgroundColor: "rgba(44, 44, 44, 0.6)",
    marginHorizontal: 10,
    padding: 20
  },
  buttonRoundedMedium: {
    width: 80,
    height: 80
  },
  buttonRoundedSmall: {
    padding: 15
  },
  buttonRoundedLarge: {
    padding: 25
  }
});

export default Navigation;
