import React from "react";
import { View, StyleSheet } from "react-native-web";
import Header from "../Header";
import Footer from "../Footer";
import Download from "../Download";
import { IS_CORDOVA } from "../../constants";
import Spacer from "../Spacer";

const Screen = ({ children, showProfil, isLogged, localhost, localIp }) => (
  <View style={styles.loginContainer}>
    <Header />
    <Spacer height={50} />
    <View style={styles.main}>{children}</View>
    <Spacer height={50} />
    <Footer localhost={localhost} localIp={localIp} showProfil={showProfil} />
    {!IS_CORDOVA && isLogged && <Download />}
  </View>
);

Screen.defaultProps = {
  showProfil: false
};

const styles = StyleSheet.create({
  main: {
    position: "static",
    justifyContent: "center",
    margin: "auto",
    width: "100%"
  },
  loginContainer: {
    position: "static",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 500,
    width: "100%",
    minHeight: "100vh",
    paddingHorizontal: 40,
    margin: "auto"
  }
});

export default Screen;
