import { streamState } from "../Stream";
import { loginThroughApi } from "../../services";
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage
} from "../../utils/localstorage";

const appState = {
  user: null,
  token: null,
  isLogged: false,
  isInRoom: false,
  isLoading: false,
  flashMessage: {
    status: "hide",
    message: null
  }
};

const appActions = {
  init: async state => {
    try {
      const token = await getItemFromLocalStorage("token");
      const { user } = await loginThroughApi(null, token);

      return {
        ...state,
        user: user ? user : appState.user,
        token: token ? token : appState.token,
        isLogged: user ? true : appState.isLogged,
        loginIsFetching: false
      };
    } catch (error) {
      return {
        loginIsFetching: false
      };
    }
  },
  initLocalhost: async state => {
    try {
      const user = await getItemFromLocalStorage("user");
      const token = await getItemFromLocalStorage("token");

      return {
        ...state,
        user: user ? JSON.parse(user) : appState.user,
        token: token ? token : appState.token,
        isLogged: user ? true : appState.isLogged,
        loginIsFetching: false
      };
    } catch (error) {
      return {
        loginIsFetching: false
      };
    }
  },
  logout: async state => {
    try {
      await removeItemFromLocalStorage("token");

      return {
        token: appState.token,
        isLogged: appState.isLogged
      };
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  setLoading: async state => {
    return {
      ...state,
      isLoading: true
    };
  },
  setLoaded: async state => {
    return {
      ...state,
      isLoading: false
    };
  },
  toggleLocalhost: async (state, actions, value) => {
    await actions.saveToLocalStorage({ name: "localhost", value });

    return {
      ...state,
      localhost: value
    };
  },
  setRemoteIp: async (state, actions, remoteIp) => {
    await actions.saveToLocalStorage({ name: "remoteIp", value: remoteIp });

    return {
      ...state,
      remoteIp,
      wifiIsSync: true
    };
  },
  setIsInRoom: async state => {
    return {
      ...state,
      isInRoom: true,
      isLoading: false
    };
  },
  disconnect: async state => {
    return {
      ...state,
      ...streamState,
      isInRoom: false,
      isLoading: false
    };
  },
  setFlashMessage: async (state, actions, message) => {
    return {
      ...state,
      flashMessage: {
        status: "visible",
        message
      }
    };
  },
  hideFlashMessage: async state => {
    return {
      ...state,
      flashMessage: {
        ...state.flashMessage,
        status: "hide"
      }
    };
  },
  getAndroidPermissions: async () => {
    const permissions = window.cordova.plugins.permissions;
    const list = [
      permissions.CAMERA,
      permissions.RECORD_AUDIO,
      permissions.ACCESS_COARSE_LOCATION
    ];

    return new Promise(() => permissions.requestPermissions(list));
  }
};

export { appState, appActions };
