// @flow
import React from "react";
import { View, Text, Switch, StyleSheet } from "react-native-web";
import Spacer from "../../Spacer";

type Props = {
  label: string,
  value: boolean,
  onValueChange: Function,
  spacerHeight: number
};

const AppSwitch = ({ label, value, onValueChange, spacerHeight }: Props) => {
  return [
    <Spacer key={1} height={spacerHeight} />,
    <View key={2} style={styles.switchContainer}>
      <Text>{label}</Text>
      <Switch
        value={value}
        activeTrackColor="#FDA11E"
        activeThumbColor="#fd711e"
        onValueChange={onValueChange}
      />
    </View>
  ];
};

AppSwitch.defaultProps = {
  spacerHeight: 15
};

const styles = StyleSheet.create({
  switchContainer: {
    flexDirection: "row",
    justifyContent: "space-between"
  }
});

export default AppSwitch;
