// @flow
import { VIDEO_FACING_MODE_USER, VIDEO_FACING_MODE_BACK } from "../constants";
import { actions } from "../store";
import { logError } from "./socket";

let localStream;

/**
 * Get local stream
 *
 * @param {Object} setting
 * @param {boolean} [callback=false]
 * @param {boolean} [isScreenSharing=false]
 * @returns
 */
async function getLocalStream(
  setting: Object,
  callback: boolean = false,
  isScreenSharing: boolean = false
) {
  return new Promise(resolve => {
    if (isScreenSharing) {
      navigator.mediaDevices
        .getDisplayMedia({ video: true })
        .then(
          stream => resolve(getLocalStreamSuccess(stream, callback)),
          logError
        );
    } else {
      navigator.getUserMedia(
        setting,
        stream => resolve(getLocalStreamSuccess(stream, callback)),
        logError
      );
    }
  });
}

/**
 * Get local stream success
 *
 * @param {Object} stream
 * @param {Function} callback
 * @returns
 */
async function getLocalStreamSuccess(stream: Object, callback: Function) {
  localStream = stream;

  if (localStream) {
    await actions.addSelfView(localStream);
  }

  if (callback) {
    callback();
  }

  return Promise.resolve();
}

/**
 * Switch camera
 *
 * @returns
 */
async function switchCamera() {
  localStream.getTracks().forEach(track => track.stop());

  const facingMode =
    AppComponent.state.userMedia.video.facingMode === VIDEO_FACING_MODE_BACK
      ? VIDEO_FACING_MODE_USER
      : VIDEO_FACING_MODE_BACK;

  const userMedia = {
    ...AppComponent.state.userMedia,
    video: {
      facingMode
    }
  };

  await actions.setUserMedia(userMedia);

  return getLocalStream(AppComponent.state.userMedia, replaceTrack, false);
}

async function backToCamera() {
  localStream.getTracks().forEach(track => track.stop());
  return getLocalStream(AppComponent.state.userMedia, replaceTrack);
}

/**
 * Swtch to screen
 *
 * @returns
 */
async function switchScreen() {
  localStream.getTracks().forEach(track => track.stop());
  return getLocalStream(null, replaceTrack, true);
}

/**
 * Replace Track for all streamer
 *
 * @returns
 */
function replaceTrack() {
  return Promise.all(
    pc
      .getSenders()
      .map(
        async sender =>
          await sender.replaceTrack(
            localStream.getTracks().find(t => t.kind === sender.track.kind),
            localStream
          )
      )
  ).catch(logError);
}

/**
 * Stop all User Media streams
 *
 * @returns
 */
function stopUserMedia() {
  return localStream.getTracks().forEach(track => track.stop());
}

export {
  localStream,
  getLocalStream,
  switchCamera,
  switchScreen,
  backToCamera,
  stopUserMedia
};
