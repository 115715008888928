// @flow
import React from "react";
import { View } from "react-native-web";

type Props = {
  height?: number,
  width?: number
};

const Spacer = ({ height, width }: Props) => <View style={{ height, width }} />;

export default Spacer;
