import { AsyncStorage } from "react-native-web";
import { VIDEO_FACING_MODE_USER } from "../../constants";

const storageState = {
  localhost: false,
  roomId: "",
  mediaDevices: false,
  userMedia: {
    audio: true,
    video: VIDEO_FACING_MODE_USER
  },
  offerOptions: {
    offerToReceiveAudio: true,
    offerToReceiveVideo: true
  },
  wifiSettings: {
    SSID: process.env.REACT_APP_WIFI_NAME,
    password: process.env.REACT_APP_WIFI_PASSWORD
  }
};

const storageActions = {
  saveToLocalStorage: async (state, actions, { name, value }) => {
    try {
      await AsyncStorage.setItem(name, value);
    } catch (error) {
      await actions.setFlashMessage(`Error : ${error}`);
    }

    return {
      ...state,
      [name]: value
    };
  },
  savePCSettingsToLocalStorage: async (
    state,
    actions,
    { property, name, value }
  ) => {
    await AsyncStorage.setItem(name, value);

    return {
      ...state,
      [property]: {
        ...state[property],
        [name]: value
      }
    };
  },
  saveWifiInformationsToLocalStorage: async (state, actions, wifi) => {
    await AsyncStorage.setItem("wifi", JSON.stringify(wifi));

    return {
      ...state,
      wifiSettings: wifi
    };
  },
  getLocalStorageData: async state => {
    const [
      wifi,
      localhost,
      remoteIp,
      roomId,
      audio,
      video,
      mediaDevices,
      offerToReceiveAudio,
      offerToReceiveVideo,
      loginEmail
    ] = await Promise.all([
      AsyncStorage.getItem("wifi"),
      AsyncStorage.getItem("localhost"),
      AsyncStorage.getItem("remoteIp"),
      AsyncStorage.getItem("roomId"),
      AsyncStorage.getItem("audio"),
      AsyncStorage.getItem("video"),
      AsyncStorage.getItem("mediaDevices"),
      AsyncStorage.getItem("offerToReceiveAudio"),
      AsyncStorage.getItem("offerToReceiveVideo"),
      AsyncStorage.getItem("loginEmail")
    ]);

    const userMedia = {
      audio: audio ? JSON.parse(audio) : state.userMedia.audio,
      video: video ? JSON.parse(video) : state.userMedia.video
    };
    const offerOptions = {
      offerToReceiveAudio: offerToReceiveAudio
        ? JSON.parse(offerToReceiveAudio)
        : state.offerOptions.offerToReceiveAudio,
      offerToReceiveVideo: offerToReceiveVideo
        ? JSON.parse(offerToReceiveVideo)
        : state.offerOptions.offerToReceiveVideo
    };

    return {
      ...state,
      localhost: JSON.parse(localhost),
      wifiSettings: wifi ? JSON.parse(wifi) : storageState.wifiSettings,
      mediaDevices: mediaDevices
        ? JSON.parse(mediaDevices)
        : state.mediaDevices,
      remoteIp,
      userMedia,
      offerOptions,
      roomId: roomId ? roomId : "",
      loginEmail: loginEmail ? loginEmail : ""
    };
  },
  setUserMedia: async (state, actions, userMedia) => {
    return {
      ...state,
      userMedia
    };
  }
};

export { storageState, storageActions };
