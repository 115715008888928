// @flow
import React from "react";
import {
  TouchableWithoutFeedback,
  StyleSheet,
  View,
  Text,
  ActivityIndicator
} from "react-native-web";
import Spacer from "../../Spacer";

type Props = {
  title: string,
  disabled?: boolean,
  onPress: Function,
  isLoading?: boolean,
  spacerHeight?: number
};

const AppButton = ({
  title,
  disabled,
  onPress,
  isLoading,
  spacerHeight
}: Props) => {
  return [
    <Spacer key={1} height={spacerHeight} />,
    <TouchableWithoutFeedback
      key={2}
      disabled={disabled || isLoading}
      onPressIn={onPress}
    >
      <View
        style={[styles.button, disabled ? styles.disabled : styles.enabled]}
      >
        {isLoading ? (
          <ActivityIndicator size="large" color="white" />
        ) : (
          <Text style={styles.text}>{title}</Text>
        )}
      </View>
    </TouchableWithoutFeedback>
  ];
};

AppButton.defaultProps = {
  disabled: false,
  isLoading: false,
  spacerHeight: 30
};

const styles = StyleSheet.create({
  button: {
    width: "100%",
    paddingHorizontal: 20,
    height: 60,
    borderRadius: 3,
    alignItems: "center",
    justifyContent: "center"
  },
  enabled: {
    backgroundColor: "#FDA11E"
  },
  disabled: {
    backgroundColor: "black",
    opacity: 0.2
  },
  text: {
    color: "white",
    fontWeight: "bold"
  }
});

export default AppButton;
