import { connect } from "../../store";
import Room from "../../components/Room";

const RoomContainer = connect(
  ({ localhost, selfView, streamers, lastStreamId, roomId }) => ({
    localhost,
    selfView,
    streamers,
    lastStreamId,
    roomId
  })
)(Room);

export default RoomContainer;
