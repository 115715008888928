const translations = {
  NETWORK_OFFLINE_PLACEHOLDER: "Set emiter IP",
  NETWORK_ONLINE_PLACEHOLDER: "Name room",
  BUTTON_JOIN_ROOM: "Join room",
  BUTTON_LOGIN: "Login",
  IP_ADDRESS: "Your ip address",
  EMITER_SHARE_AUDIO: "Share audio",
  OFFER_RECEIVE_AUDIO: "Receive audio",
  EMITER_SHARE_VIDEO: "Share video",
  EMITER_SHARE_SCREEN: "Share screen",
  OFFER_RECEIVE_VIDEO: "Receive video",
  OFFLINE_MODE: "Localhost",
  ROOM_NAME: "Room name",
  FLASH_MESSAGE_ERROR_SOCKET_IO: error => `Error: ${error}`,
  FLASH_MESSAGE_LOCAL_STORAGE_CLEAR: "Local storage cleared",
  FLASH_MESSAGE_WIFI_CLEAR: "Wifi parameters cleared",
  FLASH_MESSAGE_WIFI_CONNECT: SSID => `Connected to the ${SSID} network`,
  FLASH_MESSAGE_WIFI_ALREADY_CONNECTED:
    "You are already connected to this wifi network",
  FLASH_MESSAGE_DOWNLOAD_FAIL: "Could not download APK file",
  FLASH_MESSAGE_SCAN_WIFI_NETWORK: "Scanning your wifi network ...",
  FLASH_MESSAGE_BAD_PASSWORD: "Bad password",
  FLASH_MESSAGE_JOIN_ROOM: roomId => `You have joined the room ${roomId}`,
  FLASH_MESSAGE_USER_LEAVE_ROOM: "1 user have leave room",
  FLASH_MESSAGE_END_CONVERSATION: "Conversation finished",
  FLASH_MESSAGE_LEFT_ROOM: "You left the room",
  WIFI_PASSWORD: "Enter password",
  WIFI_BUTTON_LOGIN: "Login",
  WIFI_BUTTON_CANCEL: "Cancel"
};

export default translations;
