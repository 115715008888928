import { connect } from "../../store";
import ModalWifiSettings from "../../components/Modal/WifiSettings";

const ModalWifiSettingsContainer = connect(
  ({ modalIsOpen, isFetchingWifi }) => ({
    modalIsOpen,
    isFetchingWifi
  })
)(ModalWifiSettings);

export default ModalWifiSettingsContainer;
