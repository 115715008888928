import { actions } from "../store";
import callRemote from "./callRemote";

/**
 * Send offer to device by IP
 *
 * @returns
 */
async function sendOffer() {
  const { type, sdp } = pc.localDescription;
  try {
    const { data } = await callRemote(
      {
        type,
        sdp,
        ip: AppComponent.state.localIp,
        isOffer: true
      },
      5000
    );

    if (data) {
      const sd = new RTCSessionDescription(data);
      await pc
        .setRemoteDescription(sd)
        .catch(error => actions.setFlashMessage("Can not start communication"));
    }

    return actions.setLoaded();
  } catch (error) {
    throw Error("Fail to send offer to hotspot.");
  }
}

export default sendOffer;
