import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import "./index.css";
import AppContainer from "./containers/App";
import { Provider } from "./store";
import { version } from "../package.json";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_API_KEY,
  release: version
});

const startApp = () => {
  ReactDOM.render(
    <Provider>
      <AppContainer />
    </Provider>,
    document.getElementById("root")
  );
};

if (window.cordova) {
  document.addEventListener("deviceready", startApp, false);
} else {
  startApp();
}
