// @flow
import React from "react";
import { TextInput, StyleSheet } from "react-native-web";
import Spacer from "../../Spacer";
import { autobind } from "core-decorators";
import { ReactComponent as IconEye } from "../../../static/icons/eye.svg";
import { ReactComponent as IconEyeBlocked } from "../../../static/icons/eye-blocked.svg";

type Props = {
  value: string,
  onChangeText?: Function,
  placeholder: string,
  spacerHeight: number,
  type: string,
  autoFocus: boolean
};

type State = {
  borderColor: string,
  type: string
};

const ACTIVE_BORDER_COLOR = "#FDA11E";
const DEFAULT_BORDER_COLOR = "#E5E5E5";
const INPUT_PASSWORD_TYPE = "password";
const INPUT_TEXT_TYPE = "text";

class AppInput extends React.Component<Props, State> {
  state = {
    borderColor: DEFAULT_BORDER_COLOR,
    type: this.props.type
  };

  static defaultProps = {
    onChangeText: null,
    spacerHeight: 30,
    autoFocus: false
  };

  @autobind
  onBlur() {
    this.setState({
      borderColor: DEFAULT_BORDER_COLOR
    });
  }

  @autobind
  onFocus() {
    this.setState({
      borderColor: ACTIVE_BORDER_COLOR
    });
  }

  @autobind
  toggleType() {
    const type =
      this.state.type === INPUT_PASSWORD_TYPE
        ? INPUT_TEXT_TYPE
        : INPUT_PASSWORD_TYPE;

    this.setState({
      type
    });
  }

  render() {
    const {
      value,
      onChangeText,
      placeholder,
      spacerHeight,
      autoFocus
    } = this.props;
    const { type } = this.state;

    return [
      <div style={{ position: "relative" }}>
        <Spacer key={1} height={spacerHeight} />
        <TextInput
          key={2}
          secureTextEntry={type === INPUT_PASSWORD_TYPE}
          style={[
            styles.input,
            { borderColor: this.state.borderColor, width: "100%" }
          ]}
          value={value}
          onChangeText={onChangeText}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          placeholder={placeholder}
          autoFocus={autoFocus}
        />
        {this.props.type === INPUT_PASSWORD_TYPE && (
          <div
            style={{
              width: 20,
              position: "absolute",
              top: "calc(50% + 5px)",
              right: 20
            }}
            onClick={this.toggleType}
          >
            {type === INPUT_PASSWORD_TYPE ? <IconEye /> : <IconEyeBlocked />}
          </div>
        )}
      </div>
    ];
  }
}

const styles = StyleSheet.create({
  input: {
    height: 60,
    borderWidth: 1,
    borderRadius: 3,
    paddingHorizontal: 20
  }
});

export default AppInput;
