import { connect } from "../../store";
import Login from "../../screens/Login";
import { Button } from "../../components/Forms";

const LoginContainer = connect(({ loginEmail }) => ({
  loginEmail
}))(Login);

const LoginButtonSubmitContainer = connect(
  ({ loginIsFetching, loginError }) => ({
    isLoading: loginIsFetching && !loginError
  })
)(Button);

export { LoginButtonSubmitContainer };
export default LoginContainer;
