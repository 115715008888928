// @flow
import React from "react";
import { View, StyleSheet, ActivityIndicator, Text } from "react-native-web";
import { Switch, Input, Button } from "../../components/Forms";
// $FlowFixMe
import { ReactComponent as IconWifi } from "../../static/icons/wifi.svg";
// $FlowFixMe
import { ReactComponent as IconArrowLeft } from "../../static/icons/arrowLeft.svg";
// $FlowFixMe
import { ReactComponent as IconLoop } from "../../static/icons/loop.svg";
import translations from "../../translations";
import { actions } from "../../store";
import { IS_CORDOVA } from "../../constants";
import { isDesktop } from "../../components/App";
import ScanList from "../../components/ScanList/List";
import Underline from "../../components/Underline";
import Spacer from "../../components/Spacer";
import Title from "../../components/Title";
import Separator from "../../components/Separator";
import { autobind } from "core-decorators";
import ScreenContainer from "../../containers/Screen";
import connectToWifi from "../../utils/connectToWifi";
import ModalWifiSettingsContainer from "../../containers/ModalWifiSettings";

type State = {
  isLoading: boolean
};

type Props = {
  wifiSettings: Object,
  wifiIsConnected: boolean,
  roomId: string,
  userMedia: Object,
  mediaDevices: Object,
  offerOptions: Object,
  localhost: boolean,
  remoteIp: string,
  isLoading: boolean,
  localIp: string,
  joinRoom: Function,
  handleChange: Function,
  handleChangeUserMedia: Function,
  handleChangeOfferOptions: Function,
  handleChangeMediaDevicesOptions: Function,
  inputPlaceholder: string,
  formIsNotValid: boolean,
  isScanning: boolean,
  scanResults: Array<Object>,
  showScanResults: boolean
};

class Auth extends React.Component<Props, State> {
  state = {
    isLoading: false,
    showWifiSettingsMessage: false
  };

  componentDidMount() {
    if (IS_CORDOVA) {
      this.testWifiEnabled();
      setTimeout(() => this.props.localhost && this.tryConnectToWifi(), 1000);
    }

    setTimeout(() => {
      if (!wifiIsConnected && !isHotSpot) {
        this.setState({
          showWifiSettingsMessage: true
        });
      }
    }, 5000);
  }

  testWifiEnabled() {
    return WifiWizard2.isWifiEnabled().then(value => {
      if (!value) {
        return actions.setIsHotspot();
      }
    });
  }

  @autobind
  async getHostIp() {
    await this.setState({
      isLoading: true
    });

    await actions.getHostIp();

    return this.setState({
      isLoading: false
    });
  }

  @autobind
  async tryConnectToWifi() {
    const isWifiEnabled = await WifiWizard2.isWifiEnabled();
    const { wifiSettings } = this.props;

    if (isWifiEnabled) {
      return connectToWifi(
        wifiSettings ? wifiSettings.SSID : process.env.REACT_APP_WIFI_NAME,
        wifiSettings
          ? wifiSettings.password
          : process.env.REACT_APP_WIFI_PASSWORD
      ).catch(error => {
        return this.tryConnectToWifi();
      });
    }
  }

  render() {
    const {
      wifiIsConnected,
      roomId,
      userMedia,
      mediaDevices,
      offerOptions,
      localhost,
      remoteIp,
      joinRoom,
      handleChange,
      handleChangeUserMedia,
      handleChangeOfferOptions,
      handleChangeMediaDevicesOptions,
      inputPlaceholder,
      formIsNotValid,
      isScanning,
      scanResults,
      showScanResults,
      isHotSpot,
      wifiIsSync,
      isSyncWifi
    } = this.props;

    return (
      <ScreenContainer showProfil>
        {isScanning ? (
          <ActivityIndicator size="large" style={styles.main} />
        ) : !isScanning && showScanResults ? (
          <>
            <ScanList results={scanResults} />
            <View
              key={2}
              style={{
                flexDirection: "row",
                paddingTop: 30,
                paddingBottom: 60
              }}
            >
              <IconWifi style={{ marginRight: 10 }} />
              <Underline
                onPress={actions.scanNetwork}
                title="Scan network"
                size="small"
              />
              <IconArrowLeft style={{ marginRight: 10, marginLeft: "auto" }} />
              <Underline
                onPress={actions.hideScanResults}
                title="Cancel"
                size="small"
              />
            </View>
          </>
        ) : (
          <>
            <Title title="Parameters" />
            <Switch
              label={translations.EMITER_SHARE_AUDIO}
              value={userMedia.audio}
              onValueChange={value => handleChangeUserMedia("audio", value)}
            />
            <Switch
              label={translations.OFFER_RECEIVE_AUDIO}
              value={offerOptions.offerToReceiveAudio}
              onValueChange={value =>
                handleChangeOfferOptions("offerToReceiveAudio", value)
              }
            />
            <Switch
              label={translations.EMITER_SHARE_VIDEO}
              value={userMedia.video}
              onValueChange={value => handleChangeUserMedia("video", value)}
            />
            {!IS_CORDOVA && isDesktop && (
              <Switch
                label={translations.EMITER_SHARE_SCREEN}
                value={mediaDevices}
                onValueChange={value =>
                  handleChangeMediaDevicesOptions("mediaDevices", value)
                }
              />
            )}
            <Switch
              label={translations.OFFER_RECEIVE_VIDEO}
              value={offerOptions.offerToReceiveVideo}
              onValueChange={value =>
                handleChangeOfferOptions("offerToReceiveVideo", value)
              }
            />
            {IS_CORDOVA && (
              <>
                <Separator />
                <Title title="Network mode" />
                <Switch
                  label={translations.OFFLINE_MODE}
                  value={localhost}
                  onValueChange={value => {
                    value && this.tryConnectToWifi();
                    actions.toggleLocalhost(value);
                  }}
                />
                <Spacer height={15} />
                {localhost && (
                  <View
                    style={{ flexDirection: "row" }}
                    onClick={actions.toggleModalWifiSettings}
                  >
                    <Text style={{ flex: 1 }}>Edit wifi settings</Text>
                    <IconArrowLeft
                      style={{ transform: `rotate(180deg)`, color: "gray" }}
                    />
                  </View>
                )}
              </>
            )}
            {localhost ? (
              <>
                <Spacer height={10} />
                {wifiIsSync && (
                  <Button
                    onPress={joinRoom}
                    title="Start communication"
                    isLoading={this.state.isLoading || this.props.isLoading}
                  />
                )}
                {((isHotSpot && !wifiIsConnected) ||
                  (!isHotSpot && wifiIsConnected)) && <Spacer height={20} />}
                {isHotSpot && !wifiIsConnected && (
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ActivityIndicator />
                      <Spacer width={10} />
                      <Text>Waiting synchronization...</Text>
                    </div>
                    <Spacer height={20} />
                    <Text
                      style={{ fontSize: 11, color: "grey", marginBottom: 10 }}
                    >
                      1. Check if your hotspot is active
                    </Text>
                    <Text
                      style={{ fontSize: 11, color: "grey", marginBottom: 10 }}
                    >
                      2. Check your wifi settings
                    </Text>
                  </>
                )}
                {wifiIsConnected && !isHotSpot && (
                  <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <IconLoop />
                    <Spacer width={10} />
                    <Underline
                      title="Refresh to connect"
                      onPress={actions.syncWifi}
                    />
                    {isSyncWifi && (
                      <>
                        <Spacer width={10} />
                        <ActivityIndicator />
                      </>
                    )}
                  </div>
                )}
                {!wifiIsConnected && !isHotSpot && (
                  <>
                    <Spacer height={20} />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ActivityIndicator />
                      <Spacer width={10} />
                      <Text>Searching for a hotspot...</Text>
                    </div>
                    <Spacer height={20} />
                    {this.state.showWifiSettingsMessage && (
                      <Text
                        style={{
                          fontSize: 11,
                          color: "grey",
                          marginBottom: 10
                        }}
                      >
                        Check your wifi settings
                      </Text>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <Input
                  onChangeText={handleChange}
                  value={localhost ? remoteIp : roomId}
                  placeholder={inputPlaceholder}
                  autoFocus={!IS_CORDOVA}
                />
                <Button
                  onPress={joinRoom}
                  title={
                    localhost
                      ? translations.BUTTON_LOGIN
                      : translations.BUTTON_JOIN_ROOM
                  }
                  disabled={formIsNotValid}
                  isLoading={this.state.isLoading || this.props.isLoading}
                />
              </>
            )}
          </>
        )}
        <ModalWifiSettingsContainer wifiSettings={this.props.wifiSettings} />
      </ScreenContainer>
    );
  }
}

const styles = StyleSheet.create({
  main: {
    justifyContent: "center",
    flex: 1,
    width: "100%"
  }
});

export default Auth;
