import getHostIp from "./getHostIp";
import { actions } from "../store";
// import getWifiAlgorithm from "./getWifiAlgorithm";
import translations from "../translations";
import getLocalIp from "./getLocalIp";
import callRemote from "./callRemote";

async function connectToWifi(
  SSID,
  password,
  bindAll = false,
  algorithm = "WPA"
) {
  // const { SSID, capabilities, password } = wifi;

  return new Promise(async (resolve, reject) => {
    try {
      // const algorithm = wifi.algorithm
      //   ? wifi.algorithm
      //   : getWifiAlgorithm(capabilities);

      // Connect to wifi selected
      await WifiWizard2.connect(SSID, bindAll, password, algorithm);

      // Get local and remote IP's
      const localIp = await getLocalIp();
      const remoteIp = await getHostIp();
      await actions.saveWifiInformationsToLocalStorage({
        SSID,
        algorithm,
        password
      });
      await actions.connectToWifiSuccess({ SSID, localIp, remoteIp });
      await callRemote(
        {
          ip: localIp
        },
        5000,
        remoteIp
      );

      resolve();
      return setTimeout(
        () =>
          actions.setFlashMessage(
            translations.FLASH_MESSAGE_WIFI_CONNECT(SSID)
          ),
        500
      );
    } catch (error) {
      await actions.isFetchedWifi();
      return setTimeout(
        () => actions.setFlashMessage(`Can not connect to ${SSID}.`),
        500
      );
    }
  });
}

export default connectToWifi;
