import fetchTimeout from "./fetchTimeout";
import { actions } from "../store";

const SERVER_PORT = 8080;

const headers = {
  "Content-Type": "application/json",
  accept: "application/json"
};

async function callReceiver(ip, body) {
  try {
    const request = await fetchTimeout(`http://${ip}:${SERVER_PORT}`, {
      method: "POST",
      headers,
      body: JSON.stringify(body)
    });

    return request.json();
  } catch (error) {
    return actions.setFlashMessage(error);
  }
}

export default callReceiver;
