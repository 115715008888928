import fetchTimeout from "./fetchTimeout";
import { actions } from "../store";

const SERVER_PORT = 8080;

const headers = {
  "Content-Type": "application/json",
  accept: "application/json"
};

async function callRemote(body, timeout = null, routerIp = null) {
  try {
    const request = await fetchTimeout(
      `http://${
        routerIp ? routerIp : AppComponent.state.remoteIp
      }:${SERVER_PORT}`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(body)
      },
      timeout
    );

    return request.json();
  } catch (error) {
    actions.setLoaded();
    return setTimeout(
      () => actions.setFlashMessage("Fail to communicate with remote."),
      500
    );
  }
}

export default callRemote;
