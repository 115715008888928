import fetchTimeout from "./fetchTimeout";

// @flow
// import { __DEV__ } from "../constants";

const __DEV__ = true;

const defaultHeaders: Object = {
  "Content-Type": "application/json"
};

const callApi = async (
  slug: string,
  body: null | Object = null,
  method: string = "GET",
  headers: Object = {}
): Object => {
  const params: Object = {
    method,
    headers: {
      ...defaultHeaders,
      ...headers
    }
  };

  if (body) {
    params.body = JSON.stringify(body);
  }

  if (__DEV__) {
    console.log(
      `${method.toUpperCase()} - ${process.env.REACT_APP_REST_API_URL}${slug}`
    );
    console.log(`Headers - ${JSON.stringify(params.headers)}`);
    console.log(`Body - ${JSON.stringify(body)}`);
  }

  const request = await fetchTimeout(
    `${process.env.REACT_APP_REST_API_URL}${slug}`,
    params
  );

  if (request.status >= 400) {
    __DEV__ && console.log(request);
    throw new Error(request.statusText);
  }

  const response = await request.json();

  return response;
};

export { defaultHeaders };
export default callApi;
