// @flow
import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { TouchableOpacity, ActivityIndicator } from "react-native-web";
import { ReactComponent as IconWifiLock } from "../../../static/icons/wifiLock.svg";
import { ReactComponent as IconWifiConnected } from "../../../static/icons/wifiConnected.svg";
import { autobind } from "core-decorators";
import connectToWifi from "../../../utils/connectToWifi";
import { actions } from "../../../store";
import translations from "../../../translations";

type Props = {
  wifiSettings: Object,
  item: Object
};

class ScanItem extends React.Component<Props> {
  state = {
    isLoading: false
  };

  @autobind
  async showNotificationPrompt(wifi) {
    this.setLoading();

    const { wifiSettings } = this.props;

    try {
      if (wifiSettings && wifi.SSID === wifiSettings.SSID) {
        return connectToWifi(wifiSettings);
      }

      navigator.notification.prompt(
        translations.WIFI_PASSWORD,
        results => {
          if (results.buttonIndex == 1) {
            connectToWifi({ ...wifi, password: results.input1 }).catch(() => {
              actions.setFlashMessage(translations.FLASH_MESSAGE_BAD_PASSWORD);
              this.setLoaded();
            });
          } else if (results.buttonIndex == 2) {
            this.setLoaded();
          }
        },
        wifi.SSID,
        [translations.WIFI_BUTTON_LOGIN, translations.WIFI_BUTTON_CANCEL]
      );
    } catch (error) {
      actions.setFlashMessage(error);
    }
  }

  @autobind
  setLoading() {
    this.setState({
      isLoading: true
    });
  }

  @autobind
  setLoaded() {
    this.setState({
      isLoading: false
    });
  }

  onPressIn(item) {
    if (this.props.isActive) {
      return actions.setFlashMessage(
        translations.FLASH_MESSAGE_WIFI_ALREADY_CONNECTED
      );
    }

    return this.showNotificationPrompt(item);
  }

  render() {
    const { item, isActive } = this.props;
    const { isLoading } = this.state;
    const iconSvgStyles = { marginLeft: "auto", width: 20, height: 20 };

    return (
      <TouchableOpacity onPressIn={() => this.onPressIn(item)}>
        <View style={styles.item}>
          {isLoading && (
            <View style={styles.indicator}>
              <ActivityIndicator />
            </View>
          )}
          <Text style={[isActive && styles.isActive]}>{item.SSID}</Text>
          {isActive ? (
            <IconWifiConnected style={{ ...iconSvgStyles, width: 28 }} />
          ) : (
            <IconWifiLock style={iconSvgStyles} />
          )}
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  list: {
    flex: 1,
    width: "100%"
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 50,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#E5E5E5"
  },
  indicator: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(255, 255, 255, .6)"
  },
  isActive: {
    color: "#3adb76",
    fontWeight: "bold"
  }
});

export default ScanItem;
