// @flow
import React from "react";
import VideoList from "../Video/List";
import NavigationContainer from "../../containers/Navigation";

type Props = {
  localhost: boolean,
  selfView: Object,
  streamers: Array<Object>,
  lastStreamId: string,
  roomId: string,
  streamerFounded: number
};

const Room = ({
  localhost,
  selfView,
  streamers,
  lastStreamId,
  roomId,
  streamerFounded
}: Props) => [
  <VideoList
    key={1}
    localhost={localhost}
    selfView={selfView}
    streamers={streamers}
    lastStreamId={lastStreamId}
    mediumVideoHeight={streamerFounded ? `${100 / streamers.length}%` : "100%"}
    roomName={roomId}
    streamersCount={streamers.length + 1}
  />,
  <NavigationContainer key={2} />
];

export default Room;
