// @flow
import React from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native-web";
import Video from "../Item";
import { isDesktop, isMobile, isMedium } from "../../App";
import { actions } from "../../../store";
import translations from "../../../translations";

type Props = {
  localhost: boolean,
  selfView: Object,
  streamers: Array<Object>,
  lastStreamId: string,
  mediumVideoHeight: string,
  roomName: string,
  streamersCount: number
};

class VideoList extends React.Component {
  state = {
    isLandscape: screen.orientation.type !== "portrait-primary"
  };

  componentDidMount() {
    let isLandscape = this.state.isLandscape;

    window.addEventListener("orientationchange", () => {
      if (screen.orientation.angle === 0) {
        isLandscape = false;
      } else {
        isLandscape = true;
      }

      return this.setState({
        isLandscape
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("orientationchange", () =>
      console.log("remove orientationChange")
    );
  }

  render() {
    const {
      localhost,
      selfView,
      streamers,
      lastStreamId,
      mediumVideoHeight,
      roomName,
      streamersCount
    } = this.props;

    const { isLandscape } = this.state;

    const isLargeScreen = isLandscape;
    const isSmallScreen = isMobile && !isLandscape;

    return (
      <View
        style={[
          styles.list,
          isLargeScreen && styles.listMedium,
          isSmallScreen && styles.listSmall
        ]}
      >
        {!localhost && isMedium && (
          <View style={styles.roomNameContainer}>
            <Text style={styles.roomName}>
              {translations.ROOM_NAME} : {roomName} ({streamersCount} user
              {streamersCount > 1 && "s"})
            </Text>
          </View>
        )}
        <TouchableOpacity
          style={[
            styles.item,
            isSmallScreen && styles.itemSmall,
            isLargeScreen && { height: mediumVideoHeight },
            lastStreamId === "self-view" && styles.lastItem,
            lastStreamId === "self-view" &&
              isLargeScreen &&
              styles.lastItemSmall
          ]}
          onPress={() =>
            lastStreamId !== "self-view" &&
            actions.updateLastStreamId("self-view")
          }
        >
          <Video stream={selfView} muted={true} />
        </TouchableOpacity>
        {streamers.map(({ socketId, stream }) => {
          const isLastItem = lastStreamId === socketId;
          const isNotLastItem = !isLastItem;

          return (
            <TouchableOpacity
              key={socketId}
              style={[
                styles.item,
                !isLandscape && styles.itemSmall,
                isNotLastItem && isSmallScreen && styles.notLastItemSmall,
                isNotLastItem && isLargeScreen && { height: mediumVideoHeight },
                isLastItem && !isLandscape && styles.lastItem,
                isLastItem && isLargeScreen && styles.lastItemSmall
              ]}
              onPress={() =>
                isNotLastItem && actions.updateLastStreamId(socketId)
              }
            >
              <Video stream={stream} muted={false} />
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  roomNameContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 3,
    backgroundColor: "rgba(0, 0, 0, .6)",
    padding: 20
  },
  roomName: {
    color: "white",
    fontSize: 16,
    textShadowColor: "rgba(0, 0, 0, .7)",
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 5
  },
  list: {
    position: "static",
    flexWrap: "wrap",
    alignItems: "flex-start",
    alignContent: "flex-start",
    width: "100%"
  },
  listSmall: {
    height: "calc(100vh - 105px)",
    flexDirection: "row"
  },
  listMedium: {
    width: "25%",
    height: "100vh",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "rgba(255, 255, 255, 0.1)"
  },
  item: {
    overflow: "hidden",
    alignItems: "center"
  },
  itemSmall: {
    width: "33.33%",
    maxHeight: "30vh"
  },
  notLastItemSmall: {
    paddingTop: 5,
    paddingHorizontal: 5
  },
  notLastItemMedium: {
    flex: 1
  },
  lastItem: {
    width: "100%",
    maxHeight: "70vh",
    order: -1
  },
  lastItemSmall: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: "75%",
    height: "100vh",
    padding: 0,
    order: 2
  }
});

export default VideoList;
