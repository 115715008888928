// @flow
import React from "react";
import { View, TouchableOpacity, Text, StyleSheet } from "react-native-web";
import { ReactComponent as IconMenu } from "../../static/icons/menu.svg";
import { actions } from "../../store";
import { autobind } from "core-decorators";

type State = {
  isOpen: boolean
};

class DevTools extends React.Component<State> {
  state = {
    isOpen: false
  };

  @autobind
  toggleMenu() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const { isOpen } = this.state;

    return (
      <View style={styles.container}>
        <TouchableOpacity onPressIn={this.toggleMenu}>
          <Text style={styles.buttonRounded}>
            <IconMenu />
          </Text>
        </TouchableOpacity>
        {isOpen && (
          <View style={styles.menu}>
            <TouchableOpacity onPress={actions.contactReceiver}>
              <Text style={styles.menuItem}>Contact receiver</Text>
            </TouchableOpacity>
            <TouchableOpacity onPressIn={actions.removeLocalStorageData}>
              <Text style={styles.menuItem}>Remove local storage data</Text>
            </TouchableOpacity>
            <TouchableOpacity onPressIn={actions.remoteWifiParameters}>
              <Text style={styles.menuItem}>Remove Wifi parameters</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 30,
    left: 30
  },
  buttonRounded: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 60,
    height: 60,
    backgroundColor: "#FDA11E",
    borderRadius: "50%"
  },
  menu: {
    position: "absolute",
    bottom: "100%",
    width: 250,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#E5E5E5",
    marginBottom: 20
  },
  menuItem: {
    borderBottomWidth: 1,
    backgroundColor: "white",
    borderColor: "#E5E5E5",
    borderStyle: "solid",
    padding: 10
  }
});

export default DevTools;
