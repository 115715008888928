// @flow
import React from "react";
import { View, ScrollView, StyleSheet } from "react-native";
import ScanItemContainer from "../../../containers/ScanItem";

type Props = {
  results: Array<Object>
};

class ScanList extends React.Component {
  state = {
    currentWifiSSID: null
  };

  async componentDidMount() {
    const currentWifiSSID = await WifiWizard2.getConnectedSSID();

    this.setState({
      currentWifiSSID
    });
  }

  render() {
    const { results } = this.props;
    const { currentWifiSSID } = this.state;

    return (
      <ScrollView style={{ flex: 1, marginTop: 20 }}>
        <View style={styles.list}>
          {results.map(item => (
            <ScanItemContainer
              item={item}
              isActive={currentWifiSSID === item.SSID}
            />
          ))}
        </View>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  list: {
    flex: 1,
    width: "100%"
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 50,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#E5E5E5"
  }
});

export default ScanList;
