import { connect } from "../../store";
import App from "../../components/App";

const AppContainer = connect(
  ({
    isLogged,
    userMedia,
    mediaDevices,
    offerOptions,
    roomId,
    isInRoom,
    isLoading,
    localhost,
    localIp,
    remoteIp,
    streamers,
    isScanning,
    scanResults,
    showScanResults
  }) => ({
    isLogged,
    userMedia,
    mediaDevices,
    offerOptions,
    roomId,
    isInRoom,
    isLoading,
    localhost,
    localIp,
    remoteIp,
    streamers,
    isScanning,
    scanResults,
    showScanResults
  })
)(App);

export default AppContainer;
