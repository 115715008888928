import React, { Component } from "react";
import { autobind } from "core-decorators";

class Video extends Component {
  componentDidMount() {
    this.setVideoStream();
  }

  componentDidUpdate() {
    this.setVideoStream();
  }

  @autobind
  setVideoStream() {
    this.video.srcObject = this.props.stream;
    this.video.muted = this.props.muted;
  }

  render() {
    return (
      <video
        autoPlay
        style={styles.video}
        ref={video => (this.video = video)}
      />
    );
  }
}

const styles = {
  video: {
    width: "100%",
    height: "100%",
    background:
      "url(\"data:image/svg+xml,%3Csvg fill='%23000000' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='1 -1 100 100' style='enable-background:new 1 -1 100 100;' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M6,32.5v33.2c0,5,4,9,9,9h49.4l-51.1-51C9.1,24.5,6,28.2,6,32.5z'%3E%3C/path%3E%3Cpath d='M94.7,28.2c-0.8-0.6-1.9-0.7-2.8-0.3l-17.6,7.3v-2.7c0-5-4-9-9-9H29.6L11.8,5.7l-4,4l82.7,82.7l4-4L74,67.9 c0.2-0.7,0.3-1.4,0.3-2.1V63l17.6,7.3c1.5,0.6,3.3-0.1,3.9-1.6c0.1-0.4,0.2-0.7,0.2-1.1V30.7C96,29.7,95.5,28.8,94.7,28.2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E\") center center/60% no-repeat"
  }
};

export default Video;
