import getHostIp from "../../utils/getHostIp";
import translations from "../../translations";
import getLocalIp from "../../utils/getLocalIp";
import callRemote from "../../utils/callRemote";

const wifiState = {
  isSyncWifi: false,
  wifiIsConnected: false,
  wifiIsSync: false,
  wifiSettings: null,
  isFetchingWifi: false,
  localIp: null,
  isHotSpot: false,
  remoteIp: "",
  isScanning: false,
  showScanResults: false,
  scanResults: [],
  modalIsOpen: false
};

const wifiActions = {
  enableWifi: async (state, actions) => {
    try {
      await WifiWizard2.enableWifi();
    } catch (error) {
      setTimeout(() => actions.setFlashMessage("Error wifi connection"), 200);
    } finally {
      return state;
    }
  },
  isFetchingWifi: async state => {
    return {
      ...state,
      isFetchingWifi: true
    };
  },
  isFetchedWifi: async state => {
    return {
      ...state,
      isFetchingWifi: false
    };
  },
  setIsScanning: async state => {
    return {
      ...state,
      isScanning: true
    };
  },
  setIsScanned: async state => {
    return {
      ...state,
      isScanning: false
    };
  },
  scanNetwork: async (state, actions) => {
    await actions.setFlashMessage(translations.FLASH_MESSAGE_SCAN_WIFI_NETWORK);
    await actions.setIsScanning();
    let scanResults;

    try {
      scanResults = await WifiWizard2.scan();
    } catch (error) {
      await actions.setIsScanned();
      alert(error);
    }

    return {
      ...state,
      scanResults,
      isScanning: false,
      showScanResults: scanResults.length > 0
    };
  },
  hideScanResults: async state => {
    return {
      ...state,
      showScanResults: false
    };
  },
  connectToWifiSuccess: async (state, actions, { SSID, localIp, remoteIp }) => {
    try {
      await actions.saveToLocalStorage({ name: "localIp", value: localIp });
      await actions.saveToLocalStorage({ name: "remoteIp", value: remoteIp });
    } catch (error) {
      setTimeout(() => actions.setFlashMessage(`Error : ${error}`), 500);
    }

    return {
      ...state,
      wifiIsConnected: true,
      isHotSpot: false,
      localIp,
      remoteIp,
      showScanResults: false
    };
  },
  setWifiIsConnected: async state => {
    return {
      ...state,
      wifiIsConnected: true
    };
  },
  showScanResults: async state => {
    return {
      ...state,
      showScanResults: false
    };
  },
  getLocalIp: async state => {
    const localIp = await getLocalIp();

    return {
      ...state,
      localIp
    };
  },
  getHostIp: async (state, actions) => {
    const remoteIp = await getHostIp(state.localIp);
    await actions.saveToLocalStorage({ name: "remoteIp", value: remoteIp });

    return {
      ...state,
      remoteIp
    };
  },
  toggleModalWifiSettings: async state => {
    return {
      ...state,
      isFetchingWifi: false,
      modalIsOpen: !state.modalIsOpen
    };
  },
  setIsHotspot: async state => {
    return {
      ...state,
      isHotSpot: true
    };
  },
  isSyncWifi: async state => {
    return {
      ...state,
      isSyncWifi: true
    };
  },
  syncWifi: async (state, actions) => {
    await actions.isSyncWifi();

    try {
      await callRemote({
        ip: state.localIp,
        getIp: true
      });

      return {
        ...state,
        isSyncWifi: false,
        wifiIsConnected: true,
        wifiIsSync: true
      };
    } catch (error) {
      alert(error);
      return state;
    }
  }
};

export { wifiState, wifiActions };
