import React from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native-web";

const Underline = ({ onPress, title, isAbsolute, size, customStyles }) => (
  <View style={isAbsolute && styles.absolute}>
    <TouchableOpacity onPressIn={onPress} style={styles.container}>
      <Text style={[styles.button, styles[size], customStyles]}>{title}</Text>
    </TouchableOpacity>
  </View>
);

Underline.defaultProps = {
  isAbsolute: false,
  size: "default"
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignContent: "flex-start"
  },
  absolute: {
    position: "absolute",
    bottom: 17,
    right: 20
  },
  button: {
    fontWeight: "bold",
    borderBottomWidth: 2,
    borderColor: "inherit",
    borderStyle: "solid",
    paddingBottom: 3
  },
  small: {
    fontSize: 12
  },
  default: {
    fontSize: 14
  }
});

export default Underline;
