import callRemote from "./callRemote";
import { actions } from "../store";

/**
 * Send offer to device by IP
 *
 * @returns
 */
async function sendOffer() {
  try {
    await callRemote({ close: true });
  } catch (error) {
    return actions.setFlashMessage(error);
  }
}

export default sendOffer;
