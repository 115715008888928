import React, { Component } from "react";
import { View, StyleSheet, Dimensions, ScrollView } from "react-native-web";
import * as Sentry from "@sentry/browser";
import { autobind } from "core-decorators";
import { joinRoom, createPC, exchange, leaveRoom } from "../../utils/socket";
import { getLocalStream } from "../../utils/localStream";
import { IS_CORDOVA } from "../../constants";
import translations from "../../translations";
import { actions } from "../../store";
import RoomContainer from "../../containers/Room";
import ToastContainer from "../../containers/Toast";
import DevTools from "../DevTools";
import LoginContainer from "../../containers/Login";
import JoinRoomContainer from "../../containers/JoinRoom";

const invalidValues = [null, ""];
let AppComponent = null;

class App extends Component {
  async componentDidMount() {
    AppComponent = this;
    window.AppComponent = this;

    await actions.getLocalStorageData();

    if (this.props.localhost) {
      await actions.initLocalhost();

      const isConnectedToWifi = await WifiWizard2.getWifiIP();

      if (isConnectedToWifi) {
        setTimeout(() => actions.syncWifi(), 2000);
      }
    } else {
      await actions.init();
    }
    // IS_CORDOVA && (await actions.enableWifi());

    if (IS_CORDOVA) {
      actions.getAndroidPermissions();
      actions.getLocalIp();

      const updateUrl = `${
        process.env.REACT_APP_REST_API_LOCATION
      }/version.xml`;

      window.AppUpdate.checkAppUpdate(updateUrl);
    }
  }

  componentDidCatch(error) {
    Sentry.captureException(error);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps
    });
  }

  @autobind
  async handleChange(value) {
    if (this.props.localhost) {
      await actions.setRemoteIp(value);
    } else {
      await actions.saveToLocalStorage({ name: "roomId", value });
    }
  }

  async handleChangeUserMedia(name, value) {
    await actions.savePCSettingsToLocalStorage({
      property: "userMedia",
      name,
      value
    });

    if (value) {
      await actions.saveToLocalStorage({ name: "mediaDevices", value: false });
    }
  }

  async handleChangeOfferOptions(name, value) {
    await actions.savePCSettingsToLocalStorage({
      property: "offerOptions",
      name,
      value
    });
  }

  async handleChangeMediaDevicesOptions(name, value) {
    await actions.saveToLocalStorage({ name, value });

    if (value) {
      await actions.savePCSettingsToLocalStorage({
        property: "userMedia",
        name: "video",
        value: false
      });
    }
  }

  @autobind
  async joinRoom(data = null, isOffer = true) {
    const { roomId, userMedia, localhost } = this.props;

    await actions.setLoading();
    try {
      await Promise.all([
        actions.saveToLocalStorage({ name: "roomId", value: roomId }),
        getLocalStream(userMedia, null, this.props.mediaDevices)
      ]);
    } catch (error) {
      await actions.setLoaded();
      return actions.setFlashMessage(
        "Your configuration is wrong. You must share at least audio or video."
      );
    }

    if (localhost && isOffer) {
      return createPC(null, isOffer, true);
    }

    if (!isOffer) {
      await actions.toggleLocalhost(true);
      return exchange(data, true);
    }

    joinRoom(roomId, false);

    await actions.setIsInRoom();
    actions.setFlashMessage(translations.FLASH_MESSAGE_JOIN_ROOM(roomId));
  }

  async offlineLeaveRoom() {
    await leaveRoom(true, false);
    setTimeout(() => actions.setLoaded(), 500);
  }

  async setOfflineRemoteIp(ip) {
    await actions.setRemoteIp(ip);
    await actions.setWifiIsConnected();
  }

  render() {
    const {
      roomId,
      isLogged,
      isInRoom,
      localhost,
      remoteIp,
      streamers
    } = this.props;
    const formIsNotValid =
      (!localhost && invalidValues.includes(roomId)) ||
      (localhost && invalidValues.includes(remoteIp));
    const inputPlaceholder = localhost
      ? translations.NETWORK_OFFLINE_PLACEHOLDER
      : translations.NETWORK_ONLINE_PLACEHOLDER;
    const streamerFounded = streamers.length > 0;

    let Component;

    switch (true) {
      case isInRoom:
        Component = <RoomContainer streamerFounded={streamerFounded} />;
        break;
      case !isLogged:
        Component = <LoginContainer />;
        break;
      default:
        Component = (
          <JoinRoomContainer
            joinRoom={this.joinRoom}
            handleChange={this.handleChange}
            handleChangeUserMedia={this.handleChangeUserMedia}
            handleChangeOfferOptions={this.handleChangeOfferOptions}
            handleChangeMediaDevicesOptions={
              this.handleChangeMediaDevicesOptions
            }
            inputPlaceholder={inputPlaceholder}
            formIsNotValid={formIsNotValid}
          />
        );
    }

    return (
      <ScrollView>
        <View style={[isLogged && styles.app, isInRoom && styles.appActive]}>
          {Component}
          {process.env.NODE_ENV !== "production" && <DevTools />}
          <ToastContainer />
        </View>
      </ScrollView>
    );
  }
}

const isMobile = Dimensions.get("window").width < 1280;
const isDesktop = Dimensions.get("window").width >= 1279;
const isMedium = Dimensions.get("window").width > 1024;
const isTiny = Dimensions.get("window").height <= 640;
const styles = StyleSheet.create({
  app: {
    flex: 1,
    minHeight: Dimensions.get("window").height
  },
  appActive: {
    backgroundColor: "#151515"
  }
});

export { AppComponent, isMobile, isDesktop, isMedium, isTiny };
export default App;
