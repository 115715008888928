// @flow
import React from "react";
import { View, Text, StyleSheet, Dimensions } from "react-native-web";
import { actions } from "../../store";

type Props = {
  flashMessage: Object
};

class Toast extends React.Component<Props> {
  UNSAFE_componentWillReceiveProps() {
    setTimeout(() => actions.hideFlashMessage(), 4000);
  }

  render() {
    const { flashMessage } = this.props;
    const isVisible = flashMessage.status === "visible";

    return (
      <View style={[styles.container, isVisible && styles.show]}>
        <Text style={styles.toast}>{flashMessage.message}</Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    maxWidth: Dimensions.get("window").width - 40,
    position: "absolute",
    right: 0,
    bottom: 20,
    left: 0,
    zIndex: 999,
    textAlign: "center",
    transition: ".3s .1s",
    visibility: "hidden",
    opacity: 0,
    margin: "auto"
  },
  show: {
    opacity: 1,
    transform: "translate3d(0, -10px, 0)",
    visibility: "visible"
  },
  toast: {
    backgroundColor: "grey",
    fontSize: 12,
    color: "white",
    borderRadius: 3,
    padding: 6
  }
});

export default Toast;
