import createStore from "react-waterfall";
import { appState, appActions } from "../actions/App";
import { storageState, storageActions } from "../actions/Storage";
import { streamState, streamActions } from "../actions/Stream";
import { wifiState, wifiActions } from "../actions/Wifi";
import { devToolsState, devToolsActions } from "../actions/DevTools";
import { apiState, apiActions } from "../actions/Api";

const store = {
  initialState: {
    ...appState,
    ...storageState,
    ...wifiState,
    ...devToolsState,
    ...apiState,
    ...streamState
  },
  actionsCreators: {
    ...appActions,
    ...storageActions,
    ...wifiActions,
    ...devToolsActions,
    ...apiActions,
    ...streamActions
  }
};

export const { Provider, connect, actions } = createStore(store);
