import { connect } from "../../store";
import JoinRoom from "../../screens/JoinRoom";

const JoinRoomContainer = connect(
  ({
    isSyncWifi,
    wifiIsConnected,
    isHotSpot,
    wifiIsSync,
    wifiSettings,
    userMedia,
    mediaDevices,
    offerOptions,
    roomId,
    isInRoom,
    isLoading,
    localhost,
    localIp,
    remoteIp,
    streamers,
    isScanning,
    scanResults,
    showScanResults
  }) => ({
    isSyncWifi,
    wifiIsConnected,
    isHotSpot,
    wifiIsSync,
    wifiSettings,
    userMedia,
    mediaDevices,
    offerOptions,
    roomId,
    isInRoom,
    isLoading,
    localhost,
    localIp,
    remoteIp,
    streamers,
    isScanning,
    scanResults,
    showScanResults
  })
)(JoinRoom);

export default JoinRoomContainer;
