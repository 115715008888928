// @flow
import React from "react";
import { View, StyleSheet } from "react-native-web";
import Logo from "../Logo";
import { isTiny } from "../App";

const Header = () => (
  <View style={[styles.header, isTiny && styles.headerTiny]}>
    <Logo />
  </View>
);

const styles = StyleSheet.create({
  header: {
    paddingTop: 30
  },
  headerTiny: {
    paddingTop: 10
  }
});

export default Header;
