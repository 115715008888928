import { last } from "lodash";

const streamState = {
  selfView: null,
  streamers: [],
  lastStreamId: null
};

const streamActions = {
  addSelfView: async (state, actions, stream) => {
    return {
      ...state,
      selfView: stream
    };
  },
  addStream: async (state, actions, stream) => {
    const streamers = [...state.streamers];

    return {
      ...state,
      streamers: [...streamers, stream],
      lastStreamId: stream.socketId
    };
  },
  removeStream: async (state, actions, socketId) => {
    const streamers = state.streamers.filter(
      item => item.socketId !== socketId
    );

    return {
      ...state,
      streamers
    };
  },
  updateLastStreamId: async (state, actions, id) => {
    const lastStreamer = last(state.streamers);

    if (id) {
      return {
        ...state,
        lastStreamId: id
      };
    }

    if (lastStreamer) {
      return {
        ...state,
        lastStreamId: lastStreamer.socketId
      };
    }

    return null;
  }
};

export { streamState, streamActions };
