// @flow
import io from "socket.io-client";
import * as Sentry from "@sentry/browser";
import { ICE_SERVERS, IS_CORDOVA } from "../constants";
import { AppComponent } from "../components/App";
import sendOffer from "./sendOffer";
import { actions } from "../store";
import { localStream, stopUserMedia } from "./localStream";
import sendCloseConnection from "./sendCloseConnection";
import translations from "../translations";

let pc = null;
let socket = null;

navigator.getUserMedia =
  navigator.getUserMedia ||
  navigator.mozGetUserMedia ||
  navigator.webkitGetUserMedia ||
  navigator.msGetUserMedia;

const configuration = { iceServers: ICE_SERVERS };
const pcPeers = {};

/**
 * Join room name
 *
 * @param {string} roomID
 */
function joinRoom(roomID: string) {
  socket = io(process.env.REACT_APP_REST_SOCKET_LOCATION, {
    path: process.env.REACT_APP_REST_SOCKET_PATH
  });

  socket.on("exchange", exchange);
  socket.on("leave", leave);

  socket.on("error", error => {
    actions.setFlashMessage(translations.FLASH_MESSAGE_ERROR_SOCKET_IO(error));
    Sentry.captureException(error);
  });

  socket.on("connect_error", error => {
    Sentry.captureException(error);
  });

  socket.emit("join", roomID, socketIds => {
    console.log("join", socketIds);

    for (const i in socketIds) {
      const socketId = socketIds[i];
      createPC(socketId, true);
    }
  });
}

/**
 * Create new Peer Connection
 *
 * @param {string} socketId
 * @param {boolean} isOffer
 * @param {boolean} [isOffline=false]
 * @returns
 */
function createPC(
  socketId: string,
  isOffer: boolean,
  isOffline: boolean = false
) {
  let pc = new RTCPeerConnection(configuration);
  window.pc = pc;

  pcPeers[socketId] = pc;

  pc.onicecandidate = ({ candidate }) => {
    if (candidate === null && isOffer && isOffline) {
      try {
        sendOffer();
      } catch (error) {
        console.log(error);
      }
    }

    if (candidate && !isOffline) {
      console.log("onicecandidate", event);
      socket.emit("exchange", { to: socketId, candidate: event.candidate });
    }
  };

  /**
   * Create offer
   */
  function createOffer() {
    pc.createOffer(AppComponent.state.offerOptions)
      .then(offer => pc.setLocalDescription(offer))
      .then(() => {
        if (!isOffline) {
          socket.emit("exchange", { to: socketId, sdp: pc.localDescription });
        }
      })
      .catch(logError);
  }

  pc.onnegotiationneeded = () => {
    if (isOffer) {
      createOffer();
    }
  };

  pc.oniceconnectionstatechange = ({ target }) => {
    if (target.iceConnectionState === "connected") {
      actions.setIsInRoom();
    }
  };

  pc.onsignalingstatechange = event => {
    console.log("onsignalingstatechange", event);
  };

  pc.onaddstream = ({ stream }) => actions.addStream({ socketId, stream });

  pc.addStream(localStream);

  return pc;
}

/**
 * Exchange between websocket/localhost server
 *
 * @param {Object} data
 * @param {boolean} [isOffline=false]
 */
function exchange(data: Object, isOffline: boolean = false) {
  let pc = null;
  let fromId = null;

  if (isOffline) {
    pc = createPC(null, false, true);
  } else {
    fromId = data.from;
    pc = fromId in pcPeers ? pcPeers[fromId] : createPC(fromId, false);
  }

  if (data.sdp) {
    console.log("exchange sdp", data);
    const sdData = isOffline ? data : data.sdp;
    const sd = new RTCSessionDescription(sdData);

    pc.setRemoteDescription(sd)
      .then(() => {
        if (pc.remoteDescription.type == "offer") {
          return pc.createAnswer(AppComponent.state.offerOptions);
        }
      })
      .then(answer => pc.setLocalDescription(answer))
      .then(() => {
        if (!isOffline) {
          console.log("setLocalDescription", pc.localDescription);
          socket.emit("exchange", {
            to: fromId,
            sdp: pc.localDescription
          });
        }
      });
    // .catch(logError);
  } else {
    console.log("exchange candidate", data);
    const ic = new RTCIceCandidate(data.candidate);
    pc.addIceCandidate(ic);
  }
}

/**
 * Leave room
 *
 * @param {string} socketId
 */
async function leave(socketId: string) {
  console.log("leave", socketId);
  let pc = pcPeers[socketId];
  pc.close();
  delete pcPeers[socketId];

  await actions.removeStream(socketId);
  await actions.updateLastStreamId();
  actions.setFlashMessage(translations.FLASH_MESSAGE_USER_LEAVE_ROOM);
}

/**
 * Close WebRTC Connection
 *
 * @returns
 */
function closePeerConnection() {
  if (pc !== null) {
    pc.close();
  }
}

/**
 * Leave room
 *
 * @param {boolean} localhost
 * @param {boolean} [isOffer=true]
 * @returns
 */
async function leaveRoom(localhost: boolean, isOffer: boolean = true) {
  stopUserMedia();
  closePeerConnection();

  switch (true) {
    case localhost && isOffer:
      sendCloseConnection();
      break;
    case !localhost:
      socket.disconnect();
      socket = null;
      break;
  }

  await actions.disconnect();
  return actions.setFlashMessage(
    localhost
      ? translations.FLASH_MESSAGE_END_CONVERSATION
      : translations.FLASH_MESSAGE_LEFT_ROOM
  );
}

/**
 * Log error
 *
 * @param {Object} error
 */
function logError(error: Object) {
  actions.setLoaded();
  // setTimeout(
  //   () => actions.setFlashMessage(error.message ? error.message : "Error"),
  //   500
  // );
  console.log("logError", error);
  Sentry.captureException(error);
}

export { joinRoom, createPC, exchange, leaveRoom, logError };
