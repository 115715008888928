import React from "react";
import { View, Text, StyleSheet } from "react-native-web";
import { ReactComponent as IconDownload } from "../../static/icons/download.svg";
import { version } from "../../../package.json";
import { isMobile } from "../App";

const downloadLink = `${
  process.env.REACT_APP_REST_API_LOCATION
}/artifacts/sunlive.${version}.apk`;

const Download = () => (
  <View
    style={[
      styles.absoluteContainer,
      isMobile && styles.absoluteContainerSmall
    ]}
  >
    <a
      href={downloadLink}
      style={{ textDecoration: "none", color: "inherit" }}
      download
    >
      <View style={styles.container}>
        <IconDownload style={{ marginRight: 10 }} />
        {!isMobile && <Text style={styles.text}>Get Android App</Text>}
      </View>
    </a>
  </View>
);

const styles = StyleSheet.create({
  absoluteContainer: {
    position: "absolute",
    bottom: 30,
    right: 30
  },
  absoluteContainerSmall: {
    bottom: 25
  },
  container: {
    flexDirection: "row",
    alignItems: "center"
  },
  text: {
    fontSize: 12,
    fontFamily:
      "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
  }
});

export default Download;
