// @flow
import React from "react";
import { actions } from "../../store";
import { Text, View, StyleSheet } from "react-native-web";
import Underline from "../Underline";
import Spacer from "../Spacer";

type Props = {
  user: Object
};

const Profil = ({ user }: Props) => (
  <View style={{ flexDirection: "row" }}>
    <Text>
      {user.firstname} {user.lastname}
    </Text>
    <Spacer width={10} />
    <Text>-</Text>
    <Spacer width={10} />
    <Underline title="Logout" onPress={actions.logout} />
  </View>
);

const styles = StyleSheet.create({
  text: {
    fontSize: 11
  }
});

export default Profil;
