import { loginThroughApi } from "../../services";
import { setItemToLocalStorage } from "../../utils/localstorage";

const apiState = {
  loginEmail: "",
  loginError: null,
  loginIsFetching: true
};

const apiActions = {
  setLoginIsFetching: async state => {
    return {
      ...state,
      loginError: null,
      loginIsFetching: true
    };
  },
  loginThroughApi: async (state, actions, formData) => {
    await actions.setLoginIsFetching();
    await setItemToLocalStorage({ name: "loginEmail", value: formData.email });

    try {
      const { token, user } = await loginThroughApi(formData);

      const USER_CAN_ACCESS_SUNLIVE = user.roles.includes(
        "ROLE_CAN_ACCESS_SUNLIVE"
      );

      const saveUserToLocalSTorage = () =>
        Promise.all([
          actions.saveToLocalStorage({ name: "token", value: token }),
          actions.saveToLocalStorage({
            name: "user",
            value: JSON.stringify(user)
          })
        ]);

      USER_CAN_ACCESS_SUNLIVE
        ? await saveUserToLocalSTorage()
        : setTimeout(
            () =>
              actions.setFlashMessage(
                "This user doesn’t have access to Sun’Live"
              ),
            100
          );

      return {
        ...state,
        user: USER_CAN_ACCESS_SUNLIVE ? user : null,
        isLogged: USER_CAN_ACCESS_SUNLIVE,
        loginError: true,
        loginIsFetching: false
      };
    } catch (error) {
      await actions.setFlashMessage("Bad credentials");

      return {
        loginError: true,
        loginIsFetching: false
      };
    }
  }
};

export { apiState, apiActions };
