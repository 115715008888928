import { AsyncStorage } from "react-native-web";

const setItemToLocalStorage = async ({ name, value }) => {
  await AsyncStorage.setItem(name, value);
  return;
};

const getItemFromLocalStorage = async item => {
  const data = await AsyncStorage.getItem(item);

  if (data) {
    return data;
  }

  throw Error;
};

const removeItemFromLocalStorage = async item => {
  await AsyncStorage.removeItem(item);
  return;
};

export {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
  setItemToLocalStorage
};
