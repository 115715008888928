import React from "react";
import { Image } from "react-native-web";
import logo from "../../static/images/logo-sunlive.svg";
import { isTiny } from "../App";

const Logo = () => (
  <Image style={[styles.logo, isTiny && styles.logoTiny]} source={logo} />
);

const styles = {
  logo: {
    width: 203,
    height: 67
  },
  logoTiny: {
    width: 128,
    height: 44
  }
};

export default Logo;
