export const __DEV__ = process.env.NODE_ENV !== "production";
export const IS_CORDOVA = window.cordova;
export const IS_MOBILE = window.matchMedia("(max-width: 640px)").matches;
export const IS_PWA = window.matchMedia("(display-mode: standalone)").matches;
export const IS_LANDSCAPE = window.matchMedia("(orientation: landscape)")
  .matches;
export const IS_BROWSER = !IS_CORDOVA && window;
export const VIDEO_FACING_MODE_USER = "user";
export const VIDEO_FACING_MODE_BACK = { exact: "environment" };
export const ICE_SERVERS = [
  { urls: "stun:eu.stun.sunaero.com:3478?transport=udp" },
  {
    urls: "turn:eu.stun.sunaero.com:3478?transport=udp",
    username: "sunlive",
    credential: "Jn77unmmSq3Zvvf1MKQc"
  },
  { urls: "stun:eu.stun.sunaero.com:5349?transport=udp" },
  {
    urls: "turn:eu.stun.sunaero.com:5349?transport=udp",
    username: "sunlive",
    credential: "Jn77unmmSq3Zvvf1MKQc"
  },
  {
    urls: "turn:eu.stun.sunaero.com:3478?transport=tcp",
    username: "sunlive",
    credential: "Jn77unmmSq3Zvvf1MKQc"
  },
  {
    urls: "turn:eu.stun.sunaero.com:5349?transport=tcp",
    username: "sunlive",
    credential: "Jn77unmmSq3Zvvf1MKQc"
  }
];
