import { connect } from "../../store";
import Screen from "../../components/Screen";

const ScreenContainer = connect(({ isLogged, localhost, localIp }) => ({
  isLogged,
  localhost,
  localIp
}))(Screen);

export default ScreenContainer;
