import React from "react";
import Modal from "../Layout";
import Title from "../../Title";
import { Input, Button } from "../../Forms";
import { autobind } from "core-decorators";
import connectToWifi from "../../../utils/connectToWifi";
import { actions } from "../../../store";
import translations from "../../../translations";

class ModalWifiSettings extends React.Component {
  state = {
    SSID: "",
    password: ""
  };

  componentDidMount() {
    // alert(JSON.stringify(this.props.wifiSettings));
    this.setWifiSettings(this.props.wifiSettings);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  setWifiSettings({ SSID, password }) {
    return this.setState({
      SSID,
      password
    });
  }

  @autobind
  handleChange(value, type) {
    this.setState({
      [type]: value
    });
  }

  @autobind
  async connectToWifi() {
    const { SSID, password } = this.state;

    try {
      await actions.isFetchingWifi();
      await connectToWifi(SSID, password);
      return actions.toggleModalWifiSettings();
    } catch (error) {
      await actions.isFetchedWifi();
      return setTimeout(() => actions.setFlashMessage(error), 500);
      // alert(error);
    }
  }

  render() {
    const { modalIsOpen, isFetchingWifi } = this.props;
    const { SSID, password } = this.state;

    return (
      <Modal isOpen={modalIsOpen} canCancel={!isFetchingWifi}>
        <Title title="Wifi settings" />
        <Input
          placeholder="Wifi name"
          value={SSID}
          onChangeText={value => this.handleChange(value, "SSID")}
        />
        <Input
          placeholder="Wifi password"
          value={password}
          type="password"
          onChangeText={value => this.handleChange(value, "password")}
        />
        <Button
          title="Save and connect"
          onPress={this.connectToWifi}
          isLoading={this.props.isFetchingWifi}
        />
      </Modal>
    );
  }
}

export default ModalWifiSettings;
