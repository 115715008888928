import React from "react";
import { Text, StyleSheet } from "react-native-web";

const Title = ({ title }) => <Text style={styles.text}>{title}</Text>;

const styles = StyleSheet.create({
  text: {
    fontWeight: "bold",
    color: "#FDA11E",
    textTransform: "uppercase"
  }
});

export default Title;
