import React from "react";
import { View, Text, StyleSheet } from "react-native-web";
import { version } from "../../../package.json";

const Version = () => (
  <View>
    <Text style={styles.version}>v{version}</Text>
  </View>
);

const styles = StyleSheet.create({
  version: {
    fontSize: 11,
    fontFamily: "Roboto, Arial",
    textAlign: "center"
  }
});

export { styles };
export default Version;
