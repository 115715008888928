import { AsyncStorage } from "react-native-web";
import { storageState } from "../Storage";
import { wifiState } from "../Wifi";
import translations from "../../translations";
import fetchTimeout from "../../utils/fetchTimeout";
import callRemote from "../../utils/callRemote";
import callReceiver from "../../utils/callReceiver";

const devToolsState = {};

const devToolsActions = {
  removeLocalStorageData: async (state, actions) => {
    try {
      await AsyncStorage.clear();
      setTimeout(
        () =>
          actions.setFlashMessage(
            translations.FLASH_MESSAGE_LOCAL_STORAGE_CLEAR
          ),
        500
      );
    } catch (error) {
      setTimeout(() => actions.setFlashMessage(error), 500);
    }

    return {
      ...state,
      ...storageState,
      ...wifiState
    };
  },
  remoteWifiParameters: async (state, actions) => {
    try {
      await Promise.all([
        AsyncStorage.removeItem("wifi"),
        AsyncStorage.removeItem("localIp"),
        AsyncStorage.removeItem("remoteIp")
      ]);

      setTimeout(
        () => actions.setFlashMessage(translations.FLASH_MESSAGE_WIFI_CLEAR),
        500
      );
    } catch (error) {
      setTimeout(() => actions.setFlashMessage(error), 500);
    }

    return {
      ...state,
      ...wifiState
    };
  },
  contactReceiver: async () => {
    const { data } = await callReceiver("192.168.43.174", {
      getIp: "null"
    });

    const regexRemoteAddr = new RegExp(
      /remote-addr=192.168.\d{1,3}\.\d{1,3}/,
      "s"
    );
    const ip = data
      .match(regexRemoteAddr)
      .toString()
      .replace("remote-addr=", "");

    alert(ip);

    return;
  }
};

export { devToolsState, devToolsActions };
