import api from "../api";
import callApi from "../utils/callApi";

const getToken = async formData => {
  const { token } = await callApi(api.login, formData, "POST");
  return token;
};

const getUser = async token => {
  const user = await callApi(api.me, null, "GET", {
    Authorization: `Bearer ${token}`
  });

  return user;
};

const loginThroughApi = async (formData, userToken) => {
  const token = userToken ? userToken : await getToken(formData);
  const user = await getUser(token);

  return {
    token,
    user
  };
};

export { loginThroughApi };
