// @flow
import React from "react";
import { View, StyleSheet } from "react-native-web";
import Version from "../Version";
import { isMobile, isTiny, isDesktop } from "../App";
import ProfilContainer from "../../containers/Profil";
import { IS_CORDOVA } from "../../constants";

type Props = {
  localhost: boolean,
  localIp: string,
  showProfil: boolean
};

const Footer = ({ showProfil }: Props) => (
  <View
    style={[
      styles.footer,
      !isMobile && styles.footerMedium,
      isTiny && styles.footerTiny,
      { justifyContent: "space-between", width: "100%" }
    ]}
  >
    {showProfil && <ProfilContainer />}
    <Version />
  </View>
);

const styles = StyleSheet.create({
  footer: {
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: 30
  },
  footerTiny: {
    paddingBottom: 10
  },
  footerMedium: {
    paddingBottom: 50
  },
  hasMargin: {
    paddingRight: 5
  }
});

export default Footer;
