import React from "react";
import { actions } from "../../store";
import { Input, Button } from "../../components/Forms";
import ScreenContainer from "../../containers/Screen";
import Title from "../../components/Title";
import { autobind } from "core-decorators";
import { __DEV__, IS_CORDOVA } from "../../constants";
import { LoginButtonSubmitContainer } from "../../containers/Login";

const EMAIL_REGEX = /^\w+[\w-+\.]*\@\w+([-\.]\w+)*\.[a-zA-Z]{2,}$/;

class Login extends React.Component {
  state = {
    email: null,
    password: null,
    formIsValid: false
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (props.loginEmail) {
      return this.setState({
        email: props.loginEmail
      });
    }
  }

  @autobind
  async handleChange(value, type) {
    await this.setState({
      [type]: value
    });

    this.testFormIsValid();
  }

  @autobind
  testFormIsValid() {
    const { email, password } = this.state;
    let formIsValid;

    if (email === "" || password === "" || !email.match(EMAIL_REGEX)) {
      formIsValid = false;
    } else {
      formIsValid = true;
    }

    this.setState({
      formIsValid
    });
  }

  @autobind
  loginThroughApi(event) {
    event.preventDefault();

    return actions.loginThroughApi(this.state);
  }

  render() {
    return (
      <form onSubmit={this.loginThroughApi} ref={form => (this.form = form)}>
        <ScreenContainer>
          <Title title="Login to Sun'Live" />
          <Input
            onChangeText={value => this.handleChange(value, "email")}
            value={this.state.email}
            placeholder="Your email"
            autoFocus={!IS_CORDOVA}
          />
          <Input
            onChangeText={value => this.handleChange(value, "password")}
            type="password"
            placeholder="Your password"
            value={this.state.password}
          />
          <LoginButtonSubmitContainer
            onPress={this.loginThroughApi}
            disabled={!this.state.formIsValid}
            title="Login"
          />
        </ScreenContainer>
      </form>
    );
  }
}

export default Login;
