import React from "react";
import { View, StyleSheet } from "react-native-web";

const Separator = () => <View style={styles.separator} />;

const styles = StyleSheet.create({
  separator: {
    borderWidth: 1,
    borderColor: "#E5E5E5",
    borderStyle: "solid",
    marginVertical: 20
  }
});

export default Separator;
