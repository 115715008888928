// @flow
import * as React from "react";
import { View, StyleSheet } from "react-native-web";
import { actions } from "../../../store";
import Underline from "../../Underline";
import Spacer from "../../Spacer";

type Props = {
  children: React.Node,
  isOpen: boolean
};

const Modal = ({ children, isOpen, canCancel }: Props) => (
  <View
    style={[
      styles.container,
      { transform: `translate3d(${isOpen ? 0 : "100%"}, 0, 0)` }
    ]}
  >
    {children}
    <Spacer height={20} />
    <Underline
      title="Cancel"
      onPress={canCancel && actions.toggleModalWifiSettings}
    />
  </View>
);

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
    padding: 40,
    transition: ".3s"
  },
  buttonClose: {
    position: "absolute",
    top: 20,
    right: 20
  }
});

export default Modal;
